import { createSlice } from "@reduxjs/toolkit"
import { get, keyBy, pick, set } from "lodash"
import { mapObject } from "../../../lib/utils"

const initialState = {
  mapboxSources: {},
  mapboxLayers: {},
  infoLayers: {},
  mapboxProperties: {}
}

const mapdataSlice = createSlice({
  name: 'mapdata',
  initialState,
  reducers: {
    setMapboxProperty: (state, { payload, type }) => {
      const { key, value } = payload
      state.mapboxProperties[key] = value

      return state
    },
    setInfoLayers: (state, { payload, type }) => {
      // key-value pairs, or a list of infoLayer configurations
      let { configs } = payload

      if (Array.isArray(configs)) {
        configs = keyBy(configs, 'id')
      }

      state.infoLayers = {
        ...state.infoLayers,
        ...configs
      }

      return state
    },
    removeInfoLayers: (state, { payload, type }) => {
      // list of keys
      const { keys } = payload

      state.infoLayers = mapObject(state.infoLayers, (k, v) => (!keys.includes(k) ? [k, v] : false))

      return state
    },
    setMapboxSources: (state, { payload, type }) => {
      // key-value pairs, or a list of mapboxSource configurations
      let { configs } = payload

      if (Array.isArray(configs)) {
        configs = keyBy(configs, 'id')
      }

      state.mapboxSources = {
        ...state.mapboxSources,
        ...configs
      }

      return state
    },
    removeMapboxSources: (state, { payload, type }) => {
      // list of keys
      const { keys } = payload

      state.mapboxSources = mapObject(state.mapboxSources, (k, v) => (!keys.includes(k) ? [k, v] : false))

      return state
    },
    setMapboxLayers: (state, { payload, type }) => {
      // key-value pairs, or a list of mapboxLayer configurations
      let { configs } = payload

      if (Array.isArray(configs)) {
        configs = keyBy(configs, 'id')
      }

      const result = {
        ...keyBy(state.mapboxLayers, 'id'),
        ...configs
      }


      return state
    },
    removeMapboxLayers: (state, { payload, type }) => {
      // list of keys
      const { keys } = payload

      state.mapboxLayers = mapObject(state.mapboxLayers, (k, v) => (!keys.includes(k) ? [k, v] : false))

      return state
    },
  }
})

export const {
  setMapboxProperty,
  setInfoLayers,
  removeInfoLayers,
  setMapboxSources,
  removeMapboxSources,
  setMapboxLayers,
  removeMapboxLayers,
} = mapdataSlice.actions

export default mapdataSlice.reducer